<template>
  <div>

    <!-- DEBUG ! -->
    <div v-if='false'>
      <button @click='fill'>fill</button>
      <div>answers: {{ currentResponses }}</div>
      <div>unanswered: {{ unansweredQuestions }}</div>
    </div>


    <!-- HERO! -->
    <section class="hero is-small full-bg">
      <div class="hero-body">
        <div class="section">
          <div class='container has-text-left is-max-desktop'>
            <!-- <div> -->
              <!-- <img class='hero-img' src="@/assets/logo.png"/> -->
            <!-- </div> -->
            <h1 class="title is-size-1 has-text-white">
              Professional Learning Diagnostic
            </h1>
            <h2 class="subtitle has-text-white">by Rivet Education</h2>
            <div class="columns">
              <div class='column is-three-quarters'>
                <hr>
                <br>
                <div class='block content has-text-white'>
                  <p class='is-size-5'>Is your professional learning plan red, yellow, or green? In under 5 minutes, learn if your current plan supports HQIM implementation.</p>
                  <br>
                  <div class="">
                    <p><span class='has-text-weight-bold'>Step 1: Choose Your Focus.</span> Decide which content-specific (math, science, ELA) professional learning plan you want to evaluate.</p>
                    <p><span class='has-text-weight-bold'>Step 2: Take the Quick Diagnostic.</span> Answer the targeted questions in under five minutes.</p>
                    <p><span class='has-text-weight-bold'>Step 3: Get Instant Results.</span> See if your plan is green, yellow, or red.</p>
                    <p><span class='has-text-weight-bold'>Step 4: Plan Your Next Steps.</span> Get access to a free professional learning plan template.</p>  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <!-- CONTENT -->
    <div class='section' v-if='submitted === false'>
      <div class='container is-max-desktop'>      

        <!-- TITLE -->
        <div class='block content' v-show="false">
          <p>This diagnostic is divided into four sections to help you quickly assess if your professional learning meets the definition of <a href="https://qrco.de/bfNXiD">curriculum-based professional learning</a>. We recommend answering the diagnostic questions for one content area (math, science, or ELA). You can repeat the diagnostic as often as you want to measure multiple content areas.</p>
          <p>There are 20 questions, which should take about 15 minutes to answer. You will receive a green, yellow, or red score for each section when you submit your responses.</p>
          <br>
        </div>

     
        <!-- LOOP THROUGH DOMAINS -->
        <div v-for='(domain, d) in config' :key='"qd"+d' class='block pb-5'>
          
          <!-- <div v-if='domain.domainId === "d0" | userUsesHQIM === true'> -->
          <div>

            <!-- domain title -->
            <div class='block'>
              <h3 class='is-size-3 has-text-600 has-text-info'>{{ domain.domainTitle }}</h3>
            </div>
            
            <!-- LOOP THROUGH QUESTIONS -->
            <Question v-for="(question, q) in domain.questions" :key='"q"+q' 
                    :question='question' 
                    :domainId='domain.domainId' 
                    :isError='errorMsg !== null & unansweredQuestions.includes(question.qId)' />
                    
            </div>
        </div> 
        

        <!-- 'SUBMIT BUTTON' -->
        <!-- <div v-if='submitted === false & userUsesHQIM === true'> -->
        <div v-if='submitted === false'>
          <div class='block'>
            <button class='button is-large is-danger' @click='submitClicked'>Submit</button>
          </div>
          
          <div class='block'>
            <p v-if='errorMsg !== null' class='has-text-danger'>{{ errorMsg }}</p>
          </div>
        </div>
      </div>
    </div>

    
  </div>


</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import Question from './Question.vue';


export default {
  name: 'Home',
  components: {
    Question,
  },
  data() {
    return {
      submitted: false,
      errorMsg: null,
    }
  },
  watch: {
    submitted() {
      if (this.submitted === true) {
        this.$router.push({ 
          name: "results", 
          params: {id: this.uuid} 
        })
      }
    },
  },
  computed: {
    ...mapState(['currentResponses', 'uuid', 'config']),
    ...mapGetters(['unansweredQuestions'])
  },
  methods: {
    ...mapMutations(['setCurrentResponses']),
    ...mapActions(['submitResponses']),
    async submitClicked() {
      if (this.unansweredQuestions.length === 0) {
        this.errorMsg = null;
        await this.submitResponses();
        // console.log('submitted')
        this.submitted = true;
      } else {
        this.errorMsg = 'Please answer all questions. There are '  + this.unansweredQuestions.length + ' questions remaining.';
      }
    },
    fill() {
      const allQuestions = this.config.map(x => (x.questions.map(y => ({'qId': y.qId, 'opt': y.options})))).flat(1)
      let output = [];
      allQuestions.forEach(q => {
        const item = q.opt[Math.floor(Math.random()*q.opt.length)];
        const result = {
          'qId': q.qId,
          'val': item.val
        }
        output.push(result)
      })
      this.setCurrentResponses(output);
      this.submitClicked();
    }
  }
}
</script>

